import { useAmplitude } from 'components/Tracking/AmplitudeTracking'
import { useLoggerContext } from 'contexts/LoggerContext'
import { GraphQLError } from 'graphql'
import { reportErrorContext } from 'lib/bugsnag'
import { useCallback, useMemo } from 'react'

/*
  A Logging Sink where we could log the same event to multiple providers.
  Don't use this as a replacement for console.log during general debugging though
  as that would send all those logs to amplitude. 
*/

export type LoggerPayload = {
  [key: string]: any
  error?: Error | GraphQLError
  context?: 'Stripe' | 'Payment Page' | 'Cart Total Changed' | 'Login Page'
}

interface EventLogger {
  logEvent: (event: string, payload: LoggerPayload) => void
}

const consoleLogger: EventLogger = {
  logEvent: (event: string, payload: LoggerPayload) => {
    // eslint-disable-next-line no-console
    console.log(event, payload)
  },
}

export const bugsnagLogger: EventLogger = {
  logEvent: (event: string, payload: LoggerPayload) => {
    if (
      event === 'Verification Id has the wrong format' ||
      event === 'Payment intent ID does not match verification ID' ||
      event === 'Payment Error' ||
      event === 'Failed to fetch venue' ||
      event === 'Failed to fetch venue tags'
    ) {
      // generally an unexpected log
      if (!payload.error) {
        reportErrorContext(new Error(event), payload.context ?? 'Payment Page')
        // Don't log expected errors
        // manage-api.createOrder returns Payment Error which is mostly just card errors we should ignore
        // should we just ignore graphQL errors in general?
      } else if (
        payload.error.name !== 'UserError' &&
        payload.error.name !== 'Payment Error'
      ) {
        reportErrorContext(payload.error, payload.context ?? 'Payment Page')
      }
    }
  },
}

type LoggerOptions = {
  testLogger?: EventLogger
  defaultProperties?: Record<string, any>
}
/*
  Centralised logger can be used to log events to multiple providers
  and logs events to console in dev mode.
  @param options {LoggerOptions} pass `testLogger` to be called in test env
  @returns logEvent - fn to send log to all providers
*/
export const useLogger = (options?: LoggerOptions) => {
  const { properties: contextProperties } = useLoggerContext()
  const { testLogger, defaultProperties } = options || {}
  const { logEvent: amplitudeLogEvent, identifyApp } = useAmplitude()

  const isDevEnvironment = process.env.NODE_ENV === 'development'

  const amplitudeLogger = useMemo(
    () => ({
      logEvent: (event: string, payload: LoggerPayload) =>
        amplitudeLogEvent(event, payload),
    }),
    [amplitudeLogEvent],
  )

  const eventLoggers = useMemo(() => {
    const loggers: EventLogger[] = []

    if (isDevEnvironment) {
      loggers.push(consoleLogger)
    }

    if (testLogger && process.env.NODE_ENV === 'test') {
      loggers.push(testLogger)
    }

    if (process.env.NODE_ENV !== 'test') {
      loggers.push(bugsnagLogger)
    }

    if (!isDevEnvironment && process.env.NODE_ENV !== 'test') {
      loggers.push(amplitudeLogger)
    }

    return loggers
  }, [isDevEnvironment, testLogger, amplitudeLogger])

  const logEvent = useCallback(
    (event: string, payload?: LoggerPayload) => {
      const payloadWithContext = {
        ...contextProperties,
        ...(defaultProperties || {}),
        ...(payload || {}),
      }

      eventLoggers.forEach((logger) =>
        logger.logEvent(event, payloadWithContext),
      )
    },
    [eventLoggers, contextProperties, defaultProperties],
  )

  return {
    logEvent,
    identifyApp,
  }
}
