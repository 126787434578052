import React, { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'

export const ALLOWED_COMMON_ELEMENTS = ['text', 'p', 'em', 'strong', 'a']

export const Markdown = ({
  children,
  allowedElements = ALLOWED_COMMON_ELEMENTS,
  ...rest
}: ComponentProps<typeof ReactMarkdown>) => {
  return (
    <ReactMarkdown
      {...rest}
      allowedElements={allowedElements}
      unwrapDisallowed
      components={{
        p: ({ children }) => (
          <p className="mb-4 text-inherit my-body-md">{children}</p>
        ),
        a: ({ children, href }) => {
          return (
            <a
              className="text-inherit underline"
              target="_blank"
              href={href}
              rel="noreferrer"
            >
              {children}
            </a>
          )
        },
      }}
    >
      {children}
    </ReactMarkdown>
  )
}
